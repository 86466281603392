const Project = () => {

    return (<div>
      <a className="hover:underline" href="https://chat.zhangyuhui.xyz">
      <h1 className="font-bold text-3xl mb-4">LibreChat</h1>
      </a>
      <ul>
        <li>
          - Available for various OpenAI models(o1-preview, o1-mini, ChatGPT-4o, 4o-mini, etc.)
        </li>
        <li>
          - Deployed using the the <a href="https://github.com/danny-avila/LibreChat" className="text-blue-600 underline">LibreChat official page</a>.
        </li>
        <li>
          - Use <a href="https://www.docker.com" className="text-blue-600 underline">Docker</a> for the dev container.
        </li>
        <li>
          - Implement with balance monitor system.
        </li>
        <li>
          - Send a request for me for access
        </li>
      </ul>
<div className="text-3xl">Compiled with warnings.

[eslint] 
src/Body.js
  Line 1:29:  'useEffect' is defined but never used  no-unused-vars

src/WindowWidthContext.js
  Line 40:8:  React Hook useEffect has missing dependencies: 'widthEnum' and 'widthToEnum'. Either include them or remove the dependency array  react-hooks/exhaustive-deps

Search for the keywords to learn more about each warning.
To ignore, add // eslint-disable-next-line to the line before.

WARNING in [eslint] 
src/Body.js
  Line 1:29:  'useEffect' is defined but never used  no-unused-vars

src/WindowWidthContext.js
  Line 40:8:  React Hook useEffect has missing dependencies: 'widthEnum' and 'widthToEnum'. Either include them or remove the dependency array  react-hooks/exhaustive-deps

webpacCompiled with warnings.

[eslint] 
src/Body.js
  Line 1:29:  'useEffect' is defined but never used  no-unused-vars

src/WindowWidthContext.js
  Line 40:8:  React Hook useEffect has missing dependencies: 'widthEnum' and 'widthToEnum'. Either include them or remove the dependency array  react-hooks/exhaustive-deps

Search for the keywords to learn more about each warning.
To ignore, add // eslint-disable-next-line to the line before.

WARNING in [eslint] 
src/Body.js
  Line 1:29:  'useEffect' is defined but never used  no-unused-vars

src/WindowWidthContext.js
  Line 40:8:  React Hook useEffect has missing dependencies: 'widthEnum' and 'widthToEnum'. Either include them or remove the dependency array  react-hooks/exhaustive-deps

webpacCompiled with warnings.

[eslint] 
src/Body.js
  Line 1:29:  'useEffect' is defined but never used  no-unused-vars

src/WindowWidthContext.js
  Line 40:8:  React Hook useEffect has missing dependencies: 'widthEnum' and 'widthToEnum'. Either include them or remove the dependency array  react-hooks/exhaustive-deps

Search for the keywords to learn more about each warning.
To ignore, add // eslint-disable-next-line to the line before.

WARNING in [eslint] 
src/Body.js
  Line 1:29:  'useEffect' is defined but never used  no-unused-vars

src/WindowWidthContext.js
  Line 40:8:  React Hook useEffect has missing dependencies: 'widthEnum' and 'widthToEnum'. Either include them or remove the dependency array  react-hooks/exhaustive-deps

webpacCompiled with warnings.

[eslint] 
src/Body.js
  Line 1:29:  'useEffect' is defined but never used  no-unused-vars

src/WindowWidthContext.js
  Line 40:8:  React Hook useEffect has missing dependencies: 'widthEnum' and 'widthToEnum'. Either include them or remove the dependency array  react-hooks/exhaustive-deps

Search for the keywords to learn more about each warning.
To ignore, add // eslint-disable-next-line to the line before.

WARNING in [eslint] 
src/Body.js
  Line 1:29:  'useEffect' is defined but never used  no-unused-vars

src/WindowWidthContext.js
  Line 40:8:  React Hook useEffect has missing dependencies: 'widthEnum' and 'widthToEnum'. Either include them or remove the dependency array  react-hooks/exhaustive-deps

webpacvCompiled with warnings.

[eslint] 
src/Body.js
  Line 1:29:  'useEffect' is defined but never used  no-unused-vars

src/WindowWidthContext.js
  Line 40:8:  React Hook useEffect has missing dependencies: 'widthEnum' and 'widthToEnum'. Either include them or remove the dependency array  react-hooks/exhaustive-deps

Search for the keywords to learn more about each warning.
To ignore, add // eslint-disable-next-line to the line before.

WARNING in [eslint] 
src/Body.js
  Line 1:29:  'useEffect' is defined but never used  no-unused-vars

src/WindowWidthContext.js
  Line 40:8:  React Hook useEffect has missing dependencies: 'widthEnum' and 'widthToEnum'. Either include them or remove the dependency array  react-hooks/exhaustive-deps

webpac</div></div>);
}

export default Project;