import React, { useContext } from 'react';
import { WindowWidthContext } from './WindowWidthContext.js';
import { Route, Routes} from 'react-router';
import Post from './Post';
import Project from './Project';
import Bio from './Bio.js';
import { Link } from 'react-router-dom';

const Body = () =>{
    const [windowWidth, windowEnum] = useContext(WindowWidthContext); 
    var bg = "bg-red-200";
    var style = {};
    switch (windowEnum.check(windowWidth)) {
        case windowEnum.SMALL:
            bg="bg-yellow-200";
            break;
        
        case windowEnum.MIDIUM:
            bg="bg-blue-200";
            break;
        case windowEnum.EXLARGE:
            bg=""
            style = {width: windowWidth>1440 ? "88.889%": "1280px" }
            break;
        default:
            bg=""
            break;
    }
    return (<div className={bg+ " flex flex-row  mx-auto pt-12"}>
        <div className=' sticky top-16 w-52 pl-8 self-start flex-shrink-0 flex flex-col justify-center'>
            <Link to='/'><img className='w-52 mt-6 mb-6 rounded-full p-1 border-gray-400 border' src="./avatar.jpeg" alt="Yuhui Zhang's avatar 'saga' "></img></Link>
            <h1 className='text-3xl mt-1 font-bold shrink-0'>Yuhui Zhang</h1>
            <h3 className='text-2xl py-1'>张逾辉</h3>
            <h3 className='text-lg mb-4'>You can call me Ray</h3>
            <a className='flex flex-row py-1' href={`mailto:zhangray?subject=${encodeURIComponent("Add the email suffix of my institute to complete the full email address")}`}>
                <img src='./email-icon.png' alt="Email icon" className='h-4 my-1 mr-2'></img>
                <span className='text-gray-500 hover:underline'>Email</span>
            </a>
            <a className='flex flex-row py-1' href='https://www.linkedin.com/in/ray-zhang-16aba5324'>
                <img src='./linkedin-icon.png' alt="Email icon" className='h-4 my-1 mr-2'></img>
                <span className='text-gray-500 hover:underline'>LinkedIn</span>
            </a>
            <a className='flex flex-row py-1' href='https://github.com/zhang-yuhui'>
                <img src='./github-icon-alt.png' alt="Email icon" className='h-4 my-1 mr-2'></img>
                <span className='text-gray-500 hover:underline'>Github</span>
            </a>
            <a className='flex flex-row py-1' href='https://www.instagram.com/zhang.yu.hui'>
                <img src='./instagram-icon.png' alt="Email icon" className='h-4 my-1 mr-2'></img>
                <span className='text-gray-500 hover:underline'>Instagram</span>
            </a>

        </div>
        <div className='mr-40 ml-12'>
        <Routes>
            <Route path='/post' element={<Post/>}/>
            <Route path='/project' element={<Project/>}/>
            <Route path='/' element={<Bio/>} />
            <Route path='*' element ={<div>Wrong path!</div>} />
        </Routes>
        </div>
        </div>);
}
export default Body;