// WindowWidthContext.js
import React, { createContext, useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
// Create the Context object
export const WindowWidthContext = createContext();

// Create a Context Provider component
export const WindowWidthProvider = ({ children }) => {
    const widthEnum = Object.freeze({
        SMALL: "mobile",
        MIDIUM: "tablet",
        LARGE: "desktop",
        EXLARGE: "large-desktop",
        check : (width) =>{
            if(typeof width !== 'number'){
                return widthEnum.LARGE;
            }
            if(width < 768){
                return widthEnum.SMALL;
            }else if (width <1024){
                return widthEnum.MIDIUM;
            }else if(width <1280){
                return widthEnum.LARGE
            }else{
                return widthEnum.EXLARGE;
            }
        }
    });
    const [windowWidth, setWindowWidth] = useState([window.innerWidth, widthEnum]);
    const DELAY_MS = 50;
    useEffect(() => {
        const handleResize = debounce(() => {
            setWindowWidth([window.innerWidth, widthEnum]);
        }, DELAY_MS);

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <WindowWidthContext.Provider value={windowWidth}>
            {children}
        </WindowWidthContext.Provider>
    );
};