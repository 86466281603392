const Bio = () =>{
    return (
        <div>
            <div className='font-bold text-3xl pb-6'>Wellcome to Yuhui Zhang's homepage!</div>
            <p className="text-xl">I am an undergraduate student studying Computer Science at <a className="text-blue-600 underline" href="https://usc.edu">University of Southern California</a>.</p>
            <div className="font-bold text-3xl pb-6 pt-4">News: </div>
            <ul className="text-xl">
                <li>- 2024.9: I started my college life at USC!</li>
                <li>- 2024.5: I graduate from my highschool.</li>
            </ul>
            <h1 className="text-xl my-6 font-bold">---- 🚧🚧🚧Under construction, more content comming soon!🚧🚧🚧 ----</h1>
        </div>
    );
}

export default Bio;