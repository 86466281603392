import { Link } from "react-router-dom";

const Nav = () =>{

    return (<div className="py-4 text-2xl flex flex-row justify-start" >
        <Link className="ml-10 mr-12 w-44 font-bold shrink-0 content-center left-1/4 hover:underline" to='/'>Home</Link>
        <Link className="pr-6 font-bold shrink-0 content-center text-lg hover:underline" to='./resume.pdf' target="_blank" rel="noopener noreferrer">CV</Link>
        <Link className="px-6 font-bold shrink-0 content-center text-lg hover:underline" to='/project'>Projects</Link>
        <Link className="px-6 font-bold shrink-0 content-center text-lg hover:underline" to='/post'>Post</Link>
    </div>
    );
}
export default Nav;