import {useContext } from 'react';
import './App.css';
import Nav from './Nav.js'
import Body from './Body.js';
import { WindowWidthContext } from './WindowWidthContext.js';

function App() {
  const [windowWidth, windowEnum] = useContext(WindowWidthContext);
  console.log(windowWidth);
  console.log("________");
  var style = {};
  if(windowEnum.check(windowWidth) === windowEnum.EXLARGE){
    style = {width: windowWidth>1440 ? "88.889%": "1280px" }
  }
  return (
    
      <div className="App mx-auto" style={style}>
        <Nav/>
        <hr className='border-none h-px bg-gray-300 m-0' />
        <Body/>
        <footer className='mx-auto w-2/3 py-4 mt-12 text-gray-400 text-center border-t border-t-gray-400'>© 2023-{new Date().getFullYear()} Yuhui Zhang | View source on <a className="underline"href='https://github.com/zhang-yuhui/Personal-Website'>Github</a></footer>
      </div>
    
  );
}

export default App;
